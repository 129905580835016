<template lang="html">

  <section class="view-offer">
    <div class="cabecera-oferta">
      <div class="container">
        <h1>{{$t('offers.'+slug+'.title')}}</h1>
        <a target="_blank" :href="$t('offers.'+slug+'.inscripcion')" >
          <p class="btn-solicitar">ESKATU / SOLICITAR</p>
        </a>
      </div>
    </div>

    <div class="container">
      <div class="descripcion">
        <div v-html="$t('offers.'+slug+'.description')"></div>
        <hr>
        <div class="container-links">
          <a class="btn-solicitar"  target="_blank" :href="$t('offers.'+slug+'.inscripcion')">
            ESKATU / SOLICITAR
          </a>
          <a class="btn-bases" target="_blank" download="" :href="$t('offers.'+slug+'.bases')">
            OINARRIAK ETA MATERIALAK / BASES Y MATERIALES
          </a>
        </div>
      </div>

      <!-- <div class="descripcion">
        <h2>Requisitos</h2>
        <p>{{$t('offers.'+slug+'.requisitos')}} </p>
        
      </div> -->
    </div>
  </section>

</template>

<script lang="js">

  export default {
    name: 'view-offer',

    props: ['slug'],
    mounted() {

    },
    data() {
      return {
        activada: false,
      }
    },
    methods: {

    },
    computed: {

    }
  }


</script>

<style scoped lang="scss">
  .view-offer {}
</style>
